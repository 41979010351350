<template>
  <div>
    <DgModalCustom
      :header="$t('dsr.details.exemption.title')"
      variant="large-right"
      :showModal="true"
      @closeModal="closeModal"
    >
      <form class="dsr-exemption__form" @submit.prevent="showConfirmationModal">
        <div class="dsr-exemption__warning-container">
          <img class="dsr-exemption__warning-container__img" src="../../images/warning-24.svg" alt="" />
          {{ $t("dsr.details.exemption.alert") }}
        </div>
        <DgInput
          class="dsr-exemption__search-bar"
          inputType="search"
          :attrs="{
            placeholder: $t(`dsr.details.exemption.searchbar_placeholder`),
            value: searchQuery,
          }"
          @input="ev => handleFormInput(ev, 'searchQuery')"
          @rightIconClick="resetExemptionData"
        />
        <div class="dsr-exemption__checkbox-list">
          <DSRExemptionCase
            v-for="(exemption, index) in filteredExemptions"
            :key="index"
            :exemption="exemption"
            @change="handleExemptionSelection"
          />
        </div>
        <DgInput
          :attrs="{
            style: 'min-height:100px',
            maxlength: '40000',
            required: true,
            value: additionalInformation,
          }"
          @input="ev => handleFormInput(ev, 'additionalInformation')"
          :label="$t('dsr.details.exemption.label')"
          inputType="large"
        />
        <div class="dsr-exemption__modal-btns">
          <DgButton class="dsr-exemption__modal-btn" variant-style="outline" @click="closeModal">
            {{ $t("dsr.details.exemption.cancelBtn") }}
          </DgButton>
          <DgButton class="dsr-exemption__modal-btn" nativeType="submit" :disabled="disableSaveBtn">
            {{ $t("dsr.details.exemption.saveBtn") }}
          </DgButton>
        </div>
      </form>
    </DgModalCustom>
    <DgModalCustom
      :showModal="showChildModal"
      :confirmation="confirmOptions"
      @closeModal="closeChildModal"
      @cancelClick="closeChildModal"
      @confirmClick="raiseExemption"
      :confirmLoading="loading"
      class="sm-modal"
    />
  </div>
</template>
<script>
import { Structural } from "@/dgui-customer-components/";
import { mapGetters } from "vuex";
import DSRExemptionCase from "./DSRExemptionCase.vue";

export default {
  name: "DSRDetailsExemptionModal",
  components: {
    DgModalCustom: Structural.DgModalCustom,
    DSRExemptionCase,
  },
  data() {
    return {
      loading: false,
      showChildModal: false,
      selectedExemptionIds: [],
      additionalInformation: "",
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters(["dsrDetails", "dsrExemptions"]),
    confirmOptions() {
      return {
        title: this.$t("dsr.details.exemption.confirmation.title"),
        description: this.$t("dsr.details.exemption.confirmation.description"),
        cancel: this.$t("dsr.details.exemption.cancelBtn"),
        confirm: this.$t("dsr.details.exemption.confirmBtn"),
      };
    },
    disableSaveBtn() {
      return this.selectedExemptionIds.length === 0 || this.additionalInformation === "";
    },
    enhancedExemptionList() {
      return this.dsrExemptions.map(exemption => {
        return { ...exemption, selected: this.selectedExemptionIds.includes(exemption.id) };
      });
    },
    filteredExemptions() {
      if (this.searchQuery) {
        return this.enhancedExemptionList.filter(exemption => {
          return exemption.title.toLowerCase().includes(this.searchQuery.toLowerCase());
        });
      }
      return this.enhancedExemptionList;
    },
  },
  methods: {
    handleExemptionSelection(exemptionId, selected) {
      if (selected) {
        this.selectedExemptionIds.push(exemptionId);
      } else {
        const index = this.selectedExemptionIds.indexOf(exemptionId);
        if (index !== -1) {
          this.selectedExemptionIds.splice(index, 1);
        }
      }
    },
    handleFormInput(event, inputName) {
      this[inputName] = event.target.value;
    },
    resetExemptionData() {
      this.searchQuery = "";
    },
    showConfirmationModal() {
      this.showChildModal = true;
    },
    closeChildModal() {
      this.showChildModal = false;
    },
    closeModal() {
      this.$emit("close");
    },
    raiseExemption() {
      const { id, dsrid } = this.$route.params;
      this.loading = true;
      this.$http
        .patch(`/api1/v2/customers/${id}/data_subject_requests/${dsrid}/raise_exemption`, {
          data_subject_request: {
            exemption_ids: this.selectedExemptionIds,
            exemption_description: this.additionalInformation,
          },
        })
        .then(() => {
          this.loading = false;
          this.$store.dispatch("fetchDetailsAndHistories", {
            id,
            dsrid,
          });
          this.$dgNotification.show({
            variant: "bold",
            type: "success",
            title: this.$t("dsr.details.exemption.successMsg"),
          });
          this.closeChildModal();
          this.closeModal();
        })
        .catch(error => {
          this.loading = false;
          this.apiCatch(error);
        });
    },
  },
};
</script>

<style lang="scss">
.dsr-exemption {
  &__form {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 120px);
  }

  &__warning-container {
    display: flex;
    justify-content: space-between;
    color: #fa9334;
    align-items: flex-start;
    margin-bottom: 16px;
    &__img {
      margin-right: 6px;
    }
  }

  &__search-bar {
    margin-bottom: 16px;
  }

  &__checkbox-list {
    display: flex;
    flex-direction: column;
    border: 1px solid #dfe4e9;
    overflow-y: auto;
    margin-bottom: 16px;
  }

  &__checkbox-item {
    display: flex;
    align-items: flex-start;
    margin: 14px 14px 4px;
    padding-bottom: 10px;

    &:not(:last-child) {
      border-bottom: 1px solid #dfe4e9;
    }

    &__title {
      color: #003349;
      line-height: 1.5rem;
      font-size: medium;
    }

    &__description {
      color: #7e93a7;
      margin-top: 10px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__modal-btns {
    display: flex;
    justify-content: space-between;
    right: 30px;
    bottom: 30px;
    margin-top: 20px;
    border-top: 1px solid #dcdcdc;
    padding-top: 25px;
  }

  &__modal-btn:first-child {
    margin-right: 24px;
  }
}

header {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
</style>
