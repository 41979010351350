<template>
  <div class="dsr-templates__container">
    <div class="dsr-templates__info_box">
      <div class="dsr-templates__options">
        <dg-input
          class="dsr-templates__input"
          :selectOptions="templateOptions"
          inputType="select"
          :attrs="{ value: currentTemplate.id, style: { 'margin-right': '20px' } }"
          :label="$t('dsr.details.status.templates.selection_label')"
          @change="e => chooseTemplate(e.target.value)"
        />
        <dg-input
          class="dsr-templates__input"
          :selectOptions="languageOptions"
          inputType="select"
          @change="switchLanguage"
          :attrs="{ value: currentLanguage }"
          :label="$t('dsr.details.status.templates.language_label')"
        />
      </div>
      <button
        v-dg-tooltip:top-center="$t('dsr.details.status.templates.tooltip_email')"
        class="dsr-templates__email-btn"
        @click="emailTemplateContent"
      >
        <img src="../../images/mail-icon.svg" alt="" />
      </button>
    </div>
    <div v-if="currentTemplate.subject">
      <div class="dsr-templates__textbox_label">{{ $t("dsr.details.status.templates.subject_label") }}</div>
      <div class="dsr-templates__textbox-border">
        <div class="dsr-templates__text-container">
          <div class="dsr-templates__text" v-html="currentTemplate.subject" ref="emailSubject"></div>
          <div class="dsr-templates__btns-container">
            <button
              v-dg-tooltip:top-center="$t('dsr.details.status.templates.tooltip_copy')"
              class="dsr-templates__btn"
              @click="copyTemplateItem('emailSubject')"
            >
              <img src="../../images/copy-icon.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentTemplate.content">
      <div class="dsr-templates__textbox_label">{{ $t("dsr.details.status.templates.content_label") }}</div>
      <div class="dsr-templates__textbox-border">
        <div class="dsr-templates__text-container">
          <div class="dsr-templates__text" v-html="currentTemplate.content" ref="emailContent"></div>
          <button
            v-dg-tooltip:top-center="$t('dsr.details.status.templates.tooltip_copy')"
            class="dsr-templates__btn"
            @click="copyTemplateItem('emailContent')"
          >
            <img src="../../images/copy-icon.svg" alt="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { supportedLocales } from "@/translations/localeConfigs";

export default {
  name: "DSREmailTemplates",
  data() {
    return {
      templates: [],
      currentTemplate: {},
      currentLanguage: "",
    };
  },
  created() {
    this.currentLanguage = this.dsrDetails.language;
  },
  computed: {
    ...mapGetters(["dsrDetails"]),
    templatesLabel() {
      return this.$t("dsr.details.status.templates.copied_msg");
    },
    templateOptions() {
      const templateOptions = [];
      const prettifyIndex = index => {
        return index < 9 ? `0${index + 1}` : `${index + 1}`;
      };
      if (this.templates.length) {
        this.templates.forEach((template, index) => {
          templateOptions.push({
            name: `${prettifyIndex(index)} - ${template.title}`,
            value: template.id,
          });
        });
      }

      return templateOptions;
    },
    dsrEmail() {
      return this.dsrDetails.email;
    },
    languageOptions() {
      return supportedLocales.map(locale => ({ name: this.$t(`languages.${locale}`), value: locale }));
    },
  },
  async beforeMount() {
    // fetch email templates for given locale
    await this.fetchEmailTemplates(this.currentLanguage);
    // auto select first template
    if (this.templates.length) {
      this.currentTemplate = this.templates[0];
    }
  },
  methods: {
    async fetchEmailTemplates(locale) {
      const { id, dsrid } = this.$route.params;
      const dsrEmailTemplatesEndpoint = `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/email_templates`;
      try {
        // following code is to destructure the API response
        // and also set empty array as default to emailTemplates
        const {
          data: { data: emailTemplates = [] },
        } = await this.$http.get(dsrEmailTemplatesEndpoint, {
          params: { locale },
        });
        // set templates options after it fetches from API
        this.templates = emailTemplates;
      } catch {
        this.apiCatch(error);
      }
    },
    chooseTemplate(templateId) {
      const selectedTemplate = this.templates.find(template => {
        return template.id === templateId;
      });
      this.currentTemplate = selectedTemplate;
    },
    async switchLanguage(e) {
      this.currentLanguage = e.target.value;
      // refetch templates for newly selected locale
      await this.fetchEmailTemplates(e.target.value);
      // choose template for refreshing email content
      this.chooseTemplate(this.currentTemplate.id);
    },
    copyTemplateItem(refKey) {
      this.copyElementContents(this.$refs[refKey]);
      this.$dgNotification.show({
        variant: "bold",
        type: "success",
        title: this.$t("dsr.details.status.templates.copied_msg"),
      });
    },
    emailTemplateContent(e) {
      let subject = this.currentTemplate.subject || "";
      let body = this.currentTemplate.content.replaceAll("<br>", "\n");

      subject = encodeURIComponent(subject);
      body = encodeURIComponent(body);

      window.open(`mailto:${this.dsrEmail}?body=${body}&subject=${subject}`, "_self");
    },
  },
};
</script>
<style lang="scss" scoped>
.dsr-templates {
  &__info_box {
    justify-content: space-between;
    display: flex;
  }
  &__textbox_label {
    color: #003349;
    margin-bottom: 0.25rem;
    font-weight: 600;
  }
  &__textbox-border {
    border: 1px dashed #7e93a7;
    color: #9aa7b0;
    padding: 16px;
    background: #ffffff;
    margin-bottom: 20px;
  }
  &__options {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 16px 16px 0;
  }
  &__text {
    width: calc(100% - 100px);
  }
  &__text-container {
    font-size: 14px;
    line-height: 20px;
    color: #003349;
    display: flex;
    justify-content: space-between;
  }
  &__email-btn {
    background: #f2f4f6;
    margin-left: 16px;
    margin-top: 26px;
    border: none;
    align-self: center;
  }
  &__btn {
    img {
      width: 30px;
      height: 30px;
      color: #7e93a7;
    }
    border: none;
    background: #ffffff;
    width: 40px;
    height: 40px;
    justify-self: right;
  }
}
</style>
