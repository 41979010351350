var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DgModalCustom',{attrs:{"header":_vm.$t('dsr.details.exemption.title'),"variant":"large-right","showModal":true},on:{"closeModal":_vm.closeModal}},[_c('form',{staticClass:"dsr-exemption__form",on:{"submit":function($event){$event.preventDefault();return _vm.showConfirmationModal.apply(null, arguments)}}},[_c('div',{staticClass:"dsr-exemption__warning-container"},[_c('img',{staticClass:"dsr-exemption__warning-container__img",attrs:{"src":require("../../images/warning-24.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t("dsr.details.exemption.alert"))+" ")]),_c('DgInput',{staticClass:"dsr-exemption__search-bar",attrs:{"inputType":"search","attrs":{
          placeholder: _vm.$t(`dsr.details.exemption.searchbar_placeholder`),
          value: _vm.searchQuery,
        }},on:{"input":ev => _vm.handleFormInput(ev, 'searchQuery'),"rightIconClick":_vm.resetExemptionData}}),_c('div',{staticClass:"dsr-exemption__checkbox-list"},_vm._l((_vm.filteredExemptions),function(exemption,index){return _c('DSRExemptionCase',{key:index,attrs:{"exemption":exemption},on:{"change":_vm.handleExemptionSelection}})}),1),_c('DgInput',{attrs:{"attrs":{
          style: 'min-height:100px',
          maxlength: '40000',
          required: true,
          value: _vm.additionalInformation,
        },"label":_vm.$t('dsr.details.exemption.label'),"inputType":"large"},on:{"input":ev => _vm.handleFormInput(ev, 'additionalInformation')}}),_c('div',{staticClass:"dsr-exemption__modal-btns"},[_c('DgButton',{staticClass:"dsr-exemption__modal-btn",attrs:{"variant-style":"outline"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t("dsr.details.exemption.cancelBtn"))+" ")]),_c('DgButton',{staticClass:"dsr-exemption__modal-btn",attrs:{"nativeType":"submit","disabled":_vm.disableSaveBtn}},[_vm._v(" "+_vm._s(_vm.$t("dsr.details.exemption.saveBtn"))+" ")])],1)],1)]),_c('DgModalCustom',{staticClass:"sm-modal",attrs:{"showModal":_vm.showChildModal,"confirmation":_vm.confirmOptions,"confirmLoading":_vm.loading},on:{"closeModal":_vm.closeChildModal,"cancelClick":_vm.closeChildModal,"confirmClick":_vm.raiseExemption}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }