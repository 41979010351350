<template>
  <div class="dsr_details__alert" v-if="areExemptionsAllowed">
    <div class="dsr_details__alert_info">
      <img src="../../images/alert.svg" />
      <div class="dsr_details__alert_text">{{ $t("dsr.details.exemption.banner_message") }}</div>
    </div>
    <DgButton
      class="dsr_details__exemption_button"
      variantStyle="secondary"
      variant="compact"
      @click="openExemptionsModal"
    >
      {{ $t("dsr.details.exemption.button_text") }}
    </DgButton>
    <DSRDetailsExemptionModal v-if="showModal" @close="showModal = false" />
  </div>
</template>

<script>
import DSRDetailsExemptionModal from "./DSRDetailsExemptionModal.vue";
import { mapGetters } from "vuex";
export default {
  name: "DSRExemptionsAlert",
  components: { DSRDetailsExemptionModal },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapGetters(["dsrDetails"]),
    areExemptionsAllowed() {
      return (
        this.dsrDetails.status === "active" &&
        this.dsrDetails.exemptions_available &&
        this.dsrDetails.currentWorkstep.exemptions_allowed
      );
    },
  },
  methods: {
    openExemptionsModal() {
      this.showModal = true;
    },
  },
};
</script>
<style lang="scss">
.dsr_details {
  &__alert {
    display: flex;
    padding: 20px 25px;
    border-left: 4px solid #7e93a7;
    background-color: $lightblue;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    justify-content: space-between;

    &_info {
      display: flex;
      white-space: nowrap;
      align-items: center;
    }
    &_text {
      color: #7e93a7;
      font-weight: 600;
      line-height: 24px;
      margin-left: 24px;
    }
  }
  &__exemption_button.dg-ds-button.secondary {
    background-color: #7e93a7;
    font-size: 14px;
    padding: 8px 20px;
    margin-right: 15px;
  }
}
</style>
