<template>
  <div class="dsr_details__header" data-testId="dsr-details-header">
    <div class="dsr_details__header__left">
      <img
        class="dsr_details__header__language_flag"
        v-dg-tooltip:top-center="dsrLanguageTooltip"
        :src="countryFlagUrl"
      />
      <h4 class="dsr_details__header__title">{{ this.dsrSubjectName }}</h4>
      <div :class="`dsr_details__header__status status-${dsrCurrentStatus}`" />
      <p class="dsr_details__header__status_text">{{ $t(`dsr.details.header.status.${dsrCurrentStatus}`) }}</p>
    </div>
    <div class="dsr_details__header__right">
      <div class="dsr_details__header__deadline dsr_details__header__created_at">
        <p class="dsr_details__header__deadline__label">{{ $t("dsr.columns.created_at") }}</p>
        <p class="dsr_details__header__deadline__date_time">
          {{ formattedDate(dsrDetails.created_at) }}
        </p>
      </div>
      <div class="dsr_details__header__deadline">
        <p class="dsr_details__header__deadline__label">{{ $t("dsr.columns.deadline") }}</p>
        <p class="dsr_details__header__deadline__date_time">{{ formattedDate(dsrDetails.deadline) }}</p>
      </div>
      <DSRExtendDeadline />
      <div class="dsr_details__header__days_left">
        <p class="dsr_details__header__days__number">{{ daysLeft }}</p>
        <p class="dsr_details__header__days__desc">{{ $t("dsr.details.header.daysLeftLabel") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import isAfter from "date-fns/isAfter";
import differenceInDays from "date-fns/differenceInDays";
import DSRExtendDeadline from "./DSRExtendDeadline";
export default {
  name: "DSRDetailsHeader",
  components: {
    DSRExtendDeadline,
  },
  computed: {
    ...mapGetters(["dsrDetails"]),
    dsrCurrentStatus() {
      return this.dsrDetails.status;
    },
    dsrSubjectName() {
      return this.dsrDetails.subject_name;
    },
    dsrDeadlineDate() {
      return this.dsrDetails.deadline;
    },
    daysLeft() {
      if (this.hasDeadlinePassed) {
        return 0;
      }
      return differenceInDays(new Date(this.dsrDetails.deadline), new Date());
    },
    hasDeadlinePassed() {
      return isAfter(new Date(), new Date(this.dsrDetails.deadline));
    },
    deadlineWithDateAndTime() {
      return formatDate(this.dsrDetails.deadline);
    },
    countryFlagUrl() {
      return `https://dataguard.azureedge.net/website/images/langs/${this.dsrDetails?.language || "de"}.svg`;
    },
    dsrLanguageTooltip() {
      return this.$t("dsr.details.header.languageImageLabel", {
        language: this.$t(`languages.${this.dsrDetails.language}`),
      });
    },
  },
  methods: {
    formattedDate(string) {
      return this.formatDate(new Date(string), "dd.MM.yyyy HH:mm");
    },
  },
};
</script>
<style lang="scss">
.dsr_details__header {
  display: flex;
  justify-content: space-between;

  .dg-multiselect .multiselect-unselectedList {
    position: absolute;
    z-index: 111;
    left: 0;
    right: 0;
  }

  &__edpop {
    &-input {
      margin-bottom: 16px;
    }
    &-warn {
      color: #fa9334;
      margin-bottom: 24px;
    }
  }

  .dg-form-input {
    margin-top: 24px;
  }

  p {
    margin-bottom: 0;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__language_flag {
    margin-right: 20px;
  }

  &__title {
    font-size: 1.5rem;
    position: relative;
    margin-bottom: 0;

    &::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      background-color: #c63040;
      display: block;
      bottom: -15px;
      left: 0;
    }
  }

  &__status_text {
    margin-left: 6px;
    text-transform: capitalize;
  }

  &__status {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 16px;

    &.status-pending {
      background-color: #fbca04;
    }
    &.status-active {
      background-color: #0e8a16;
    }
    &.status-finished {
      background-color: #dddddd;
    }
    &.status-failed {
      background-color: #36b2e8;
    }
  }

  &__deadline {
    margin-right: 12px;
  }

  &__created_at {
    margin-right: 30px;
  }

  &__deadline__label {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 16px;
  }

  &__deadline__date_time {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 20px;
  }

  &__days_left {
    background-color: #f2f4f6;
    text-align: center;
    padding: 3px 5px;
    margin-left: 32px;
  }

  &__days__number {
    color: #36b2e8;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 32px;
  }

  &__days__desc {
    color: #66808e;
    font-size: 0.75rem;
    line-height: 16px;
  }
}
</style>
