<template>
  <div class="dg-showmore" @click.prevent="showMore">
    <div v-if="content.length > 250 && showMoreVisible">
      {{ contentTruncate }}<dg-link>{{ $t("dsr.show_more") }}</dg-link>
    </div>
    <div v-else>{{ content }}</div>
  </div>
</template>
<script>
export default {
  name: "DSRStatusShowMore",
  data() {
    return {
      showMoreVisible: true,
    };
  },
  props: {
    content: {
      type: String,
    },
  },
  computed: {
    contentTruncate() {
      return this.content.substring(0, 250);
    },
  },
  methods: {
    showMore() {
      this.showMoreVisible = !this.showMoreVisible;
    },
  },
};
</script>
<style lang="scss" scoped>
.dg-showmore {
  cursor: pointer;
  white-space: pre-line;
  word-break: break-word;
}
</style>
