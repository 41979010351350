<template>
  <div data-testId="dsr-current-workstep">
    <div class="dsr-details__label" v-if="dsrHasFailed">
      {{ currentWorkstep.fail_title }}
    </div>
    <div class="dsr-details__label" v-else>
      {{ currentWorkstep.title }}
    </div>
    <div class="dsr-details__label-desc">
      <p v-if="dsrHasFailed" v-html="currentWorkstep.fail_description"></p>
      <p v-else v-html="currentWorkstep.description"></p>
      <dg-button
        v-can:edit="['data_subject_requests', 'addon_data_subject_requests']"
        class="dsr-details__add-status"
        variant-style="secondary"
        @click="showStatusModal = true"
        v-if="dsrNotFailed && dsrNotFinished"
      >
        <img src="../../images/file-add-24.svg" alt="" /> {{ $t("dsr.details.status.addBtnTitle") }}
      </dg-button>
    </div>
    <div v-if="fetchingHistories" class="dsr-details__tabs__loader"></div>
    <template v-else>
      <div
        v-for="(history, index) in currentHistory"
        :key="index"
        class="dsr-details__workflow dsr-details__workflow-item"
      >
        <DgDropDown
          v-if="history.type === 'custom'"
          class="dsr-details__options"
          title="&middot;&middot;&middot;"
          :vals="statusOptions"
          @clickedItem="val => onSelectOption(val, history)"
        ></DgDropDown>
        <div class="dsr-details__label">
          <span class="dsr-details__title"> {{ history.title }} &middot; </span>
          <span class="dsr-details__timestamp">
            {{ history.updated_at | changeDateFormat }} (By {{ history.author | authorName }})
          </span>
        </div>
        <DSRStatusShowMore :content="history.description" />
        <div class="dsr-details__w-attachments">
          <a
            :href="file.url"
            target="_blank"
            class="dsr-details__w-attachment"
            v-for="(file, i) in history.files"
            :key="i"
            :title="file.filename"
          >
            <div class="dsr-details__w-attachment--title">
              {{ file.filename }}
            </div>
            <div class="dsr-details__w-attachment--size">
              {{ file.byte_size | formatBytes }}
            </div>
          </a>
        </div>
      </div>
    </template>
    <div
      v-if="dsrNotFailed && dsrNotFinished && currentHistory.length === 0"
      class="dsr-details__workflow dsr-details__workflow--empty"
    >
      <div><img src="../../images/pen-line.svg" alt="" /></div>
      <div>{{ $t("dsr.details.status.empty.current") }}</div>
    </div>
    <DgModalCustom
      data-testId="dg-delete-status-modal"
      :confirmation="deleteOptions"
      @closeModal="showDeleteModal = false"
      @cancelClick="showDeleteModal = false"
      @confirmClick="deleteConfirm"
      :showModal="showDeleteModal"
      :confirmLoading="deleteLoading"
    ></DgModalCustom>
    <DSRDetailsStatusModal
      data-testId="dg-manage-status-modal"
      :show="showStatusModal"
      :editStatus="editStatus"
      :currentWorkStepId="currentWorkstep.id"
      @afterSave="fetchHistory"
      @close="closeStatusModal"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import DSRStatusShowMore from "./DSRStatusShowMore.vue";
import format from "date-fns/format";
import { Structural, Basic } from "@/dgui-customer-components/";

import DSRDetailsStatusModal from "./DSRDetailsStatusModal.vue";

export default {
  name: "DSRDetailsCurrentHistory",
  components: {
    DgDropDown: Basic.DgDropDown,
    DgModalCustom: Structural.DgModalCustom,
    DSRDetailsStatusModal,
    DSRStatusShowMore,
  },
  data() {
    return {
      currentSelectedHistory: null,
      showStatusModal: false,
      showDeleteModal: false,
      deleteLoading: false,
      editStatus: {
        title: "",
        content: "",
        files: [],
        state: "add",
      },
    };
  },
  computed: {
    ...mapGetters(["dsrDetails", "histories", "fetchingHistories"]),
    currentWorkstep() {
      return this.dsrDetails.currentWorkstep;
    },
    currentHistory() {
      return this.histories.currentHistory;
    },
    statusOptions() {
      return [this.$t("dsr.details.status.options.edit"), this.$t("dsr.details.status.options.delete")];
    },
    dsrHasFailed() {
      return this.dsrDetails.status === "failed";
    },
    dsrNotFailed() {
      return !this.dsrHasFailed;
    },
    dsrNotFinished() {
      return this.dsrDetails.status !== "finished";
    },
    dsrStatusHistoryEndpoint() {
      const { id, dsrid } = this.$route.params;
      return `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/histories`;
    },
    deleteOptions() {
      return {
        title: this.$t("dsr.details.status.delete.title"),
        description: this.$t("dsr.details.status.delete.description"),
        cancel: this.$t("dsr.details.status.delete.cancelBtn"),
        confirm: this.$t("dsr.details.status.delete.confirmBtn"),
      };
    },
  },
  filters: {
    changeDateFormat(date) {
      return format(new Date(date), "dd.MM.yyyy HH:mm");
    },
    formatBytes(bytes) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
    },
    authorName(status) {
      return `${status.first_name} ${status.last_name}`;
    },
  },
  methods: {
    deleteConfirm() {
      const { id, title, content, data_subject_request_workstep_id } = this.currentSelectedHistory;
      this.deleteLoading = true;
      this.$http({
        url: `${this.dsrStatusHistoryEndpoint}/${id}`,
        method: "DELETE",
      }).then(() => {
        this.fetchHistory();
        this.deleteLoading = false;
        this.showDeleteModal = false;
        this.currentSelectedHistory = null;
      });
    },
    fillEditStatusForm() {
      const { id, title, description, files } = this.currentSelectedHistory;
      this.editStatus = {
        ...this.editStatus,
        state: "edit",
        currentStatusId: id,
        title,
        content: description,
        files,
      };
    },
    closeStatusModal() {
      this.showStatusModal = false;
    },
    onSelectOption(selectedOption, currentHistory) {
      this.currentSelectedHistory = currentHistory;
      if (selectedOption === this.$t("dsr.details.status.options.edit")) {
        this.fillEditStatusForm();
        this.showStatusModal = true;
      } else if (selectedOption === this.$t("dsr.details.status.options.delete")) {
        this.showDeleteModal = true;
      }
    },
    fetchHistory() {
      const { id, dsrid } = this.$route.params;
      this.$store.dispatch("fetchHistories", { id, dsrid });
    },
  },
};
</script>
<style lang="scss" scoped>
.dsr-details__add-status {
  img {
    margin-right: 8px;
  }
}
</style>
