<template>
  <DgModalCustom
    v-can:edit="['data_subject_requests', 'addon_data_subject_requests']"
    :header="$t('dsr.details.status.create.title')"
    variant="large-right"
    btnsAlign="right"
    @closeModal="closeModal"
    :showModal="show"
    :confirmLoading="loading"
  >
    <form @submit.prevent="createStatusHistory" autocomplete="off">
      <div>
        <DgInput
          :label="$t('dsr.details.status.create.headline')"
          name="status-headline"
          :value="title"
          @input="changeTitle"
        />
      </div>
      <div>
        <DgInput
          :hintText="$t('dsr.details.status.create.updateHelperText')"
          required
          :label="$t('dsr.details.status.create.update')"
          textarea
          name="status-udpate"
          :value="content"
          @input="changeContent"
        />
      </div>
      <div>
        <DgDragnDrop
          :hintText="$t('dsr.details.status.create.filesHelperText')"
          @filesDropped="droppedFiles"
          @maxFilesError="maxFilesError"
          @invalidFileTypeError="invalidFileType"
          @fileRemoved="removeFile"
          :maxFiles="5"
          :maxSize="5"
          :initialFiles="prefillFiles"
          data-testId="dg-dsr-status-file"
        >
          <template v-slot:container>
            <div class="cxr-wrapper__dnd">
              <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M31 19H1"
                  stroke="#9AA7B0"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16 14V1"
                  stroke="#9AA7B0"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M22 10H26L31 19V29H1V19L6 10H10"
                  stroke="#9AA7B0"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M11 6L16 1L21 6"
                  stroke="#9AA7B0"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M26 24H21"
                  stroke="#9AA7B0"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>

              <div>
                <div class="cxr-wrapper__dnd-title">
                  {{ $t("dsr.details.status.create.files") }}
                </div>
                <div class="cxr-wrapper__dnd-desc">
                  {{ $t("dsr.details.status.create.allowedFiles") }}
                </div>
              </div>
            </div>
          </template>
        </DgDragnDrop>
        <p v-if="error" class="cxr-wrapper__error-message">{{ errorText }}</p>
      </div>
      <div class="dsr-details__status__confirm-btns">
        <dg-button
          class="dsr-details__status__confirm-btn"
          variant-style="outline"
          @click="closeModal"
          :disabled="loading"
        >
          {{ $t("dsr.details.cancel") }}
        </dg-button>
        <dg-button
          class="dsr-details__status__confirm-btn"
          nativeType="submit"
          :loading="loading"
          :disabled="!Boolean(content)"
        >
          {{ $t("dsr.details.save_status") }}
        </dg-button>
      </div>
    </form>
  </DgModalCustom>
</template>

<script>
import { Structural, Form } from "@/dgui-customer-components/";

export default {
  name: "DSRDetailsStatusModal",
  components: {
    DgModalCustom: Structural.DgModalCustom,
    DgInput: Form.DgInput,
    DgDragnDrop: Form.DgDragnDrop,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currentWorkStepId: {
      type: String,
      default: "",
    },
    editStatus: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      currentStatusId: null,
      title: "",
      content: "",
      files: [],
      loading: false,
      error: false,
      errorText: "",
      state: "add",
      prefillFiles: [],
      filesToRemove: [],
    };
  },
  computed: {
    dsrStatusHistoryEndpoint() {
      const { id, dsrid } = this.$route.params;
      return `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/histories`;
    },
  },
  watch: {
    editStatus(newVal) {
      this.state = "edit";
      this.currentStatusId = newVal.currentStatusId;
      this.title = newVal.title;
      this.content = newVal.content;
      this.filesToRemove = [];
      this.prefillFiles = newVal.files.map(file => {
        return { name: file.filename, size: file.byte_size, data: file };
      });
    },
  },
  methods: {
    changeTitle(val) {
      this.title = val;
    },
    changeContent(val) {
      this.content = val;
    },
    droppedFiles(files) {
      this.files = files.filter(file => !file.data);
    },
    removeFile(index) {
      if (this.state === "edit") {
        this.filesToRemove.push(this.prefillFiles[index]);
        this.prefillFiles.splice(index, 1);
      }
    },
    invalidFileType(e) {
      if (e) {
        this.error = true;
        this.errorText = this.$t("dsr.details.status.create.error.fileType");
      } else {
        this.error = false;
        this.errorText = "";
      }
    },
    maxFilesError(e) {
      if (e) {
        this.error = true;
        this.errorText = this.$t("dsr.details.status.create.error.maxFiles");
      } else {
        this.error = false;
        this.errorText = "";
      }
    },
    createStatusHistory() {
      this.loading = true;
      let endpoint = `/post-form?url=${this.dsrStatusHistoryEndpoint}`;
      if (this.state === "edit") {
        endpoint += `/${this.currentStatusId}`;
      }
      const formData = new FormData();
      formData.append("data_subject_request_workstep_id", this.currentWorkStepId);
      formData.append("title", this.title);
      formData.append("description", this.content);
      this.files.map((file, index) => {
        formData.append(`files[${index}]`, file);
      });
      if (this.filesToRemove.length) {
        const removeFilesArr = this.filesToRemove.map(file => {
          return {
            id: file.data.id,
            _destroy: true,
          };
        });
        formData.append("files_attachments_attributes", JSON.stringify(removeFilesArr));
      }
      this.$http({
        url: endpoint,
        method: this.state === "add" ? "POST" : "PUT",
        headers: { "Content-Type": "multipart/form-data" },
        data: formData,
      })
        .then(() => {
          this.currentStatusId = null;
          this.$emit("afterSave");
          this.closeModal();
        })
        .catch(error => {
          this.loading = false;
          this.apiCatch(error);
        });
    },
    resetData() {
      this.currentStatusId = null;
      this.title = "";
      this.content = "";
      this.files = [];
      this.loading = false;
      this.error = false;
      this.errorText = "";
      this.state = "add";
      this.prefillFiles = [];
      this.filesToRemove = [];
    },
    closeModal() {
      this.resetData();
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
.dsr-details__status__confirm-btns {
  display: flex;
}
.dsr-details__status__confirm-btn:not(:last-child) {
  margin-right: 24px;
}
</style>
