<template>
  <div v-if="dsrErr">
    <ErrorComponent @onClick="goBack" :btnTitle="$t('dsr.details.btnLabels.error')" />
  </div>
  <div v-else-if="fetchingDsrDetails">
    <div class="dg-ppm-details__shimmer-wrapper">
      <div class="shim-container">
        <div class="shine lines"></div>
      </div>
      <div class="shine box"></div>
      <div class="shim-container">
        <div class="shine lines"></div>
        <div class="shine lines"></div>
      </div>
    </div>
  </div>

  <div v-else class="dsr-details">
    <div>
      <div class="dsr-details__go-back__container">
        <a @click="goBack" class="dsr-details__go-back">
          <img
            class="dsr-details__go-back__icon"
            src="../images/left-arrow.svg"
            :alt="$t('dsr.details.btnLabels.goBack')"
          />
          {{ $t("dsr.details.btnLabels.goBack") }}
        </a>
      </div>
      <DgPanel>
        <template #header>
          <DSRDetailsHeader />
        </template>
        <template #default>
          <DSRDetailsInfo />
          <template v-if="dsrDetails.further_details">
            <DSRMessage :message="dsrDetails.further_details" />
          </template>
          <div class="mrT">
            <DgProcessFlow
              :currentStep="dsrDetails.currentWorkstep"
              :list="dsrDetails.worksteps"
              data-testId="dsr-details-worksteps"
            ></DgProcessFlow>
          </div>
          <DSRExemptionsAlert />
        </template>
      </DgPanel>
    </div>
    <DSRDetailsStatus />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { Structural } from "@/dgui-customer-components/";
import ErrorComponent from "@/engines/common/components/ErrorComponent";
import DSRDetailsHeader from "../components/DSRDetails/DSRDetailsHeader.vue";
import DSRDetailsInfo from "../components/DSRDetails/DSRDetailsInfo.vue";
import DSRMessage from "../components/DSRDetails/DSRMessage.vue";
import DSRDetailsStatus from "../components/Histories/DSRDetailsStatus.vue";
import DSRExemptionsAlert from "../components/DSRDetails/DSRExemptionsAlert.vue";
export default {
  name: "DSR-Details",
  components: {
    DgPanel: Structural.DgPanel,
    DgProcessFlow: Structural.DgProcessFlow,
    ErrorComponent,
    DSRDetailsHeader,
    DSRDetailsInfo,
    DSRMessage,
    DSRDetailsStatus,
    DSRExemptionsAlert,
  },
  computed: {
    ...mapGetters(["fetchingDsrDetails", "dsrErr", "dsrDetails", "dsrExemptions"]),
  },
  created() {
    const { id, dsrid } = this.$route.params;
    this.$store.dispatch("fetchDetailsAndHistories", {
      id,
      dsrid,
    });
    this.$store.dispatch("fetchDsrExemptions", {
      id,
      dsrid,
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
.dsr-details {
  .dg-panel-header {
    padding: 24px;
    border-bottom: 1px solid #c9d2d7;
  }

  .dsr-details__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #003349;
    position: relative;
    margin-bottom: 8px;
  }

  .dsr-details__label-desc {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    > p {
      width: 70%;
    }
    > button {
      align-self: end;
    }
  }

  .dg-button {
    margin-left: 16px;
  }

  &__status__confirm-btns {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }

  &__go-back {
    margin: 5px 0 20px 0;
    display: flex;
    flex-shrink: 1;
    cursor: pointer;
    color: #7e93a7;
    opacity: 0.7;
    font-size: 16px;

    &:active,
    &:hover {
      opacity: 1;
    }

    &__container {
      display: flex;
    }

    &__icon {
      width: 14px;
      margin-right: 4px;
    }
  }
}
</style>
