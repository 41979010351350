<template>
  <div class="dsr-exemption__checkbox-item">
    <DgCheckbox v-model="exemption.selected" @input="onInput" />
    <div>
      <p class="dsr-exemption__checkbox-item__title">{{ exemption.title }}</p>
      <p class="dsr-exemption__checkbox-item__description">{{ exemption.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DSRExemptionCase",
  props: {
    exemption: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    onInput(selected) {
      this.$emit("change", this.exemption.id, selected);
    },
  },
};
</script>
