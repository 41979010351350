<template>
  <div class="dsr_details__message">
    <img src="../../images/dsr_message.svg" />
    <div class="dsr_details__message_info">
      <div class="dsr_details__message_title">{{ $t("dsr.details.dsrMessageLabel") }}</div>
      <div class="dsr_details__message_desc">{{ message }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DSRMessage",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss">
.dsr_details__message {
  display: flex;
  padding: 16px 24px;
  border-left: 4px solid #86241d;
  background-color: #f5eaea;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;

  &_info {
    margin-left: 24px;
  }

  &_title {
    color: #7a1f23;
    font-weight: 600;
    line-height: 24px;
  }

  &_desc {
    margin-top: 9px;
    font-size: 0.875rem;
    color: #5f1a1c;
    font-weight: 400;
    line-height: 20px;
    word-break: break-word;
  }
}
</style>
