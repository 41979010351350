<template>
  <div class="dsr_details__info">
    <DgButton variantStyle="secondary" variant="compact" v-can:edit="requiredPermission" @click="openEditPanel">
      <img width="22" height="18" src="../../images/pen-line2.svg" />
    </DgButton>

    <div class="dsr_details__info_container">
      <div v-for="(item, index) in detailsExcerpt" :key="index" class="dsr_details__info__detail">
        <p class="dsr_details__info__label">{{ $t(`dsr.columns.${index}`) }}</p>
        <p class="dsr_details__info__value">{{ item }}</p>
      </div>
    </div>
    <DSRDetailsEditModal
      v-can:edit="requiredPermission"
      :dsrDetails="dsrDetails"
      v-if="showEditDetailsModal"
      @close="showEditDetailsModal = false"
    />
  </div>
</template>
<script>
import DSRDetailsEditModal from "../DSRDetails/DSRDetailsEditModal.vue";
import { mapGetters } from "vuex";

export default {
  name: "DSRDetailsInfo",
  components: { DSRDetailsEditModal },
  data() {
    return {
      showEditDetailsModal: false,
    };
  },
  computed: {
    ...mapGetters(["dsrDetails"]),
    requiredPermission() {
      return ["dsr_data_editing", "dsr_addon_data_editing"];
    },
    detailsExcerpt() {
      return {
        email: this.dsrDetails.email,
        subject_name: this.dsrDetails.subject_name,
        subject_relation: this.dsrDetails.subject_relation,
        data_subject_request_type_name: this.dsrDetails.data_subject_request_type_name,
      };
    },
    dsrCreatedAt() {
      return this.formatDate(new Date(this.dsrDetails.created_at), "yyyy-MM-dd");
    },
  },
  methods: {
    openEditPanel() {
      this.showEditDetailsModal = true;
    },
  },
};
</script>
<style lang="scss">
.dsr_details__info {
  padding: 20px;
  background-color: #f2f4f6;
  position: relative;

  &_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    grid-gap: 18px;
  }

  .dg-ds-button.compact.secondary {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }

  &__label,
  &__value {
    margin-bottom: 0.3rem;
    color: $dark;
    font-weight: 600;
    line-height: 20px;
    overflow-wrap: break-word;
  }

  &__label {
    color: #4d7080;
  }

  &__id {
    position: absolute;
    top: 5px;
    left: -5px;
    padding: 2px 6px;
    background-color: #36b2e8;
    color: $white;
    font-weight: 600;
    line-height: 20px;

    p {
      margin: 0;
    }
  }
}
</style>
