<template>
  <div class="dsr-details__tabs">
    <DgTabs data-testId="dsr-details-tabs" @onTabClick="onTabChange">
      <!-- current workstep tab -->
      <DgTab
        v-if="currentHistory && dsrNotFinished && !exemptionRaised"
        :name="$t('dsr.details.status.tabs.current')"
        :selected="selectedTab === currentHistoryTabName"
      >
        <DSRDetailsCurrentHistory />
      </DgTab>
      <!-- status history tab -->
      <DgTab
        v-if="previousHistory && previousHistory.length != 0"
        :name="$t('dsr.details.status.tabs.history')"
        :selected="selectedTab === previousHistoryTabName"
      >
        <DSRDetailsPreviousHistory />
      </DgTab>
      <!-- email templates tab -->
      <DgTab
        v-if="hasEmailTemplates"
        :name="$t('dsr.details.status.tabs.templates')"
        :selected="selectedTab === emailTemplatesTabName"
      >
        <DSREmailTemplates />
      </DgTab>
    </DgTabs>
    <DgModalCustom
      data-testId="dsr-confirm-proceed-modal"
      :confirmation="proceedOptions"
      @closeModal="closeProceedModal"
      @cancelClick="closeProceedModal"
      @confirmClick="proceedConfirm"
      :showModal="showProceedModal"
      :confirmLoading="proceedLoading"
    ></DgModalCustom>
    <DgModalCustom
      data-testId="dsr-confirm-fail-modal"
      :confirmation="failOptions"
      @closeModal="closeFailModal"
      @cancelClick="closeFailModal"
      @confirmClick="failConfirm"
      :showModal="showFailModal"
      :confirmLoading="proceedLoading"
    ></DgModalCustom>
    <div class="text-right flex justify-content-end plB" data-testId="dsr-control-buttons">
      <dg-button
        class="dsr-details__tabs-btn"
        variant-style="secondary"
        v-if="currentWorkStep.can_fail && dsrNotFailed"
        @click="failModal"
      >
        {{ currentWorkStep.fail_title }}
      </dg-button>
      <dg-button
        v-can:edit="requiredPermission"
        class="dsr-details__tabs-btn"
        v-if="dsrDetails.last_step && dsrNotFinished"
        @click="proceedModal"
      >
        {{ $t("dsr.details.btnLabels.close") }}
      </dg-button>
      <dg-button
        v-can:edit="requiredPermission"
        class="dsr-details__tabs-btn"
        v-else-if="dsrNotFinished && dsrNotFailed"
        @click="proceedModal"
      >
        {{ $t("dsr.details.btnLabels.proceed") }}
      </dg-button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Structural } from "@/dgui-customer-components/";
import DSRDetailsCurrentHistory from "./DSRDetailsCurrentHistory.vue";
import DSRDetailsPreviousHistory from "./DSRDetailsPreviousHistory.vue";
import DSREmailTemplates from "./DSREmailTemplates.vue";

export default {
  name: "DSRDetailsStatus",
  components: {
    DgTabs: Structural.DgTabs,
    DgTab: Structural.DgTab,
    DgModalCustom: Structural.DgModalCustom,
    DSRDetailsCurrentHistory,
    DSRDetailsPreviousHistory,
    DSREmailTemplates,
  },
  data() {
    return {
      showProceedModal: false,
      showFailModal: false,
      proceedLoading: false,
      failLoading: false,
      selectedTab: "",
      currentHistoryTabName: this.$t("dsr.details.status.tabs.current"),
      previousHistoryTabName: this.$t("dsr.details.status.tabs.history"),
      emailTemplatesTabName: this.$t("dsr.details.status.tabs.templates"),
    };
  },
  created() {
    let selectedTab = this.currentHistoryTabName;
    if (this.dsrDetails.status === "finished" || this.exemptionRaised) {
      selectedTab = this.previousHistoryTabName;
    }
    this.selectedTab = selectedTab;
  },
  computed: {
    ...mapGetters(["dsrDetails", "histories"]),
    requiredPermission() {
      return ["dsr_processing", "dsr_addon_processing"];
    },
    currentWorkStep() {
      return this.dsrDetails.currentWorkstep;
    },
    currentHistory() {
      return this.histories.currentHistory;
    },
    previousHistory() {
      return this.histories.previousHistory;
    },
    exemptionRaised() {
      return this.currentHistory.length > 0 && this.currentHistory[0].subject === "exemption";
    },
    proceedOptions() {
      return {
        title: this.$t("dsr.details.proceed.title"),
        description: this.$t("dsr.details.proceed.description"),
        cancel: this.$t("dsr.details.proceed.cancelBtn"),
        confirm: this.$t("dsr.details.proceed.confirmBtn"),
      };
    },
    failOptions() {
      return {
        title: this.$t("dsr.details.fail.title"),
        description: this.$t("dsr.details.fail.description"),
        cancel: this.$t("dsr.details.fail.cancelBtn"),
        confirm: this.$t("dsr.details.fail.confirmBtn"),
      };
    },
    hasEmailTemplates() {
      return Boolean(this.currentWorkStep.dsr_email_templates?.length);
    },
    dsrNotFailed() {
      return this.dsrDetails.status !== "failed";
    },
    dsrNotFinished() {
      return this.dsrDetails.status !== "finished";
    },
    proceedEndpoint() {
      const { id, dsrid } = this.$route.params;
      return `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/proceed`;
    },
    dsrStatusHistoryEndpoint() {
      const { id, dsrid } = this.$route.params;
      return `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/histories`;
    },
    dsrFailEndpoint() {
      const { id, dsrid } = this.$route.params;
      return `/api1/v2/customers/${id}/data_subject_requests/${dsrid}/fail`;
    },
  },
  methods: {
    getDetailsAndHistories() {
      const { id, dsrid } = this.$route.params;
      this.$store.dispatch("fetchDetailsAndHistories", {
        id,
        dsrid,
      });
    },
    onTabChange(tabName) {
      this.selectedTab = tabName;
    },
    failConfirm() {
      this.failLoading = true;
      this.$http
        .patch(this.dsrFailEndpoint)
        .then(() => {
          this.getDetailsAndHistories();
          this.failLoading = false;
          this.showFailModal = false;
        })
        .catch(error => {
          this.failLoading = false;
          this.apiCatch(error);
        })
        .finally(() => {
          this.loadingHistory = false;
        });
    },
    proceedConfirm() {
      this.proceedLoading = true;
      this.$http
        .patch(this.proceedEndpoint)
        .then(() => {
          this.getDetailsAndHistories();
          this.proceedLoading = false;
          this.showProceedModal = false;
        })
        .catch(error => {
          this.proceedLoading = false;
          this.apiCatch(error);
        });
    },
    proceedModal() {
      this.showProceedModal = true;
    },
    failModal() {
      this.showFailModal = true;
    },
    closeProceedModal() {
      this.showProceedModal = false;
    },
    closeFailModal() {
      this.showFailModal = false;
    },
  },
};
</script>
<style lang="scss">
.dsr-details__tabs {
  background: #f2f4f6;
  padding: 0 40px;
  border: 1px solid #e6ebed;
  border-bottom: none;

  &__loader {
    margin: 40px auto;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 45px;
    height: 45px;
    animation: spin-loader 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  }

  .dsr-details__workflow {
    background: #fff;
    position: relative;
    padding: 18px 24px;
    margin-top: 18px;

    &:first-child {
      margin-top: 0;
    }

    &--empty {
      height: 148px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #7e93a7;
    }

    .dsr-details__options {
      position: absolute;
      right: 0;
      top: 0;
    }

    .dsr-details__timestamp {
      color: #7e93a7;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }

    .dsr-details__w-attachments {
      display: grid;
      grid-template-columns: repeat(auto-fit, 280px);
      grid-gap: 12px;
      margin-top: 18px;

      .dsr-details__w-attachment {
        cursor: pointer;
        background: #e9edef;
        display: flex;
        padding: 12px 18px;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        background-image: url(../../images/paperclip.svg);
        background-position: 16px center;
        background-repeat: no-repeat;
        padding-left: 46px;
        flex-wrap: nowrap;
        text-decoration: none;
        color: #254a5d;

        &:active,
        &:hover,
        &:focus {
          color: #254a5d;
          opacity: 0.8;
        }

        .dsr-details__w-attachment--title {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 80%;
        }
        .dsr-details__w-attachment--size {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #7e93a7;
        }
      }
    }
  }

  .cxr-wrapper {
    &__dnd {
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      height: 100%;
      font-size: 12px;
      color: #9aa7b0;

      svg {
        width: 32px;
        height: 32px;
        margin-right: 18px;
      }

      &__dnd-title {
        color: #0b344b;
        font-size: 12px;
      }

      &__dnd-desc {
        line-height: 20px;
      }
    }

    &__error-message {
      color: $red;
      margin: 10px 0;
    }
  }
}

.dsr-details__tabs-btn:not(:last-child) {
  margin-right: 24px;
}
@keyframes spin-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
