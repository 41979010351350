<template>
  <div>
    <div class="dsr_details__header__extend">
      <dg-button
        v-can:edit="['dsr_deadline_extension', 'dsr_addon_deadline_extension']"
        v-if="!dsrDetails.deadline_extended && !isDSRClosed"
        :loading="loading"
        @click="showExtendModal = true"
        variant="compact"
        >{{ $t("dsr.details.header.extendBtnLabel") }}</dg-button
      >
    </div>
    <dg-modal
      data-testId="dsr-extend-deadline-modal"
      @submit="extendDeadline"
      @close="showExtendModal = false"
      :show="showExtendModal"
      :options="extendModalOps"
      size="sm"
    >
      <div class="dsr_details__header__edpop">
        <div class="dsr_details__header__edpop-warn">
          <img src="../../images/warning-24.svg" alt="" /> {{ $t("dsr.details.extend.warning") }}
        </div>
        <div class="dsr_details__header__edpop-input">
          <dg-selectMenu
            :required="select.required"
            :label="select.label"
            :selectOptions="select.options"
            @input="reasonSelectChange"
          />
        </div>
        <div class="dsr_details__header__edpop-input">
          <dg-input
            :attrs="{ style: 'min-height:100px', maxlength: '40000', required: true }"
            :label="$t('dsr.details.extend.label')"
            inputType="large"
            @input="changeExtendContent"
          ></dg-input>
        </div>
      </div>
      <p v-if="extendError" class="error">{{ $t("dsr.details.extend.error") }}</p>
    </dg-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "DSRExtendDealine",
  data() {
    return {
      loading: false,
      extendError: false,
      showExtendModal: false,
      extendData: {
        reasons: null,
        content: null,
      },
      select: {
        label: this.$t("dsr.details.extend.reason_label"),
        required: true,
        options: {
          multiple: true,
          closeOnSelect: false,
          options: [
            {
              label: this.$t("dsr.details.extend.reasons.complexity"),
            },
            {
              label: this.$t("dsr.details.extend.reasons.multitude"),
            },
            {
              label: this.$t("dsr.details.extend.reasons.identification"),
            },
            {
              label: this.$t("dsr.details.extend.reasons.rights_infringement"),
            },
            {
              label: this.$t("dsr.details.extend.reasons.other"),
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters(["dsrDetails"]),
    selectAttrs() {
      return {
        name: "reason",
        placeholder: "Select reason",
        id: "reason",
        required: true,
      };
    },
    extendModalOps() {
      return { title: this.$t("dsr.details.extend.title"), submitLabel: "Save", closeLabel: "Cancel" };
    },
    isDSRClosed() {
      return ["failed", "finished"].includes(this.dsrDetails.status);
    },
  },
  methods: {
    changeExtendContent(e) {
      this.extendData.content = e.target.value;
    },
    reasonSelectChange(val) {
      this.extendData.reasons = val
        .map(item => {
          return item.label;
        })
        .join(",");
    },
    extendDeadline() {
      this.extendError = false;
      if (this.extendData.reasons && this.extendData.content) {
        // Make an API call
        const { id, dsrid } = this.$route.params;
        this.loading = true;
        this.$http
          .patch(`/api1/v2/customers/${id}/data_subject_requests/${dsrid}/extend_deadline`, {
            data_subject_request: {
              deadline_extension_title: this.extendData.reasons,
              deadline_extension_content: this.extendData.content,
            },
          })
          .then(() => {
            this.loading = false;
            this.showExtendModal = false;
            this.$store.dispatch("fetchDetailsAndHistories", {
              id,
              dsrid,
            });
            this.$dgNotification.show({
              variant: "bold",
              type: "success",
              title: this.$t("dsr.details.extend.successMsg"),
            });
          })
          .catch(error => {
            this.loading = false;
            this.apiCatch(error);
          });
      } else {
        this.extendError = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.error {
  color: #b72e35;
}
</style>
