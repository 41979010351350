<template>
  <div>
    <div v-if="fetchingHistories" class="dsr-details__tabs__loader"></div>
    <div v-else class="dsr-details__workflow dsr-details__history" v-for="pItem in previousHistory" :key="pItem.id">
      <p class="dsr-details__history__title" v-if="pItem.title">{{ pItem.title }}</p>
      <div
        :class="['dsr-details__history__item', { first: j === 0, last: j == pItem.history.length - 1 }]"
        v-for="(history, j) in pItem.history"
        :key="j"
      >
        <div class="dsr-details__label">
          <span class="dsr-details__title"> {{ history.title }} &middot; </span>
          <span class="dsr-details__timestamp">
            {{ history.updated_at | changeDateFormat }}
            (By {{ history.author | authorName }})
          </span>
        </div>
        <div class="dsr-details__history__content">
          <div :ref="`content-p`" class="dsr-details__timestamp dsr-details__history__text">
            <DSRStatusShowMore :content="history.description" />
          </div>
          <div v-if="history.files.length != 0" class="dsr-details__history__file">
            <a
              target="_blank"
              :title="file.filename"
              :href="file.url"
              v-for="(file, index) in history.files"
              :key="index"
              class="dsr-details__history__attachment"
            >
              <div class="dsr-details__history__file-name">
                {{ file.filename }}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import format from "date-fns/format";
import DSRStatusShowMore from "./DSRStatusShowMore.vue";

export default {
  name: "DSRDetailsPreviousHistory",
  components: {
    DSRStatusShowMore,
  },
  computed: {
    ...mapGetters(["histories", "fetchingHistories"]),
    previousHistory() {
      return this.histories.previousHistory;
    },
  },
  filters: {
    changeDateFormat(date) {
      return format(new Date(date), "dd.MM.yyyy HH:mm");
    },
    authorName(status) {
      return `${status.first_name} ${status.last_name}`;
    },
  },
  methods: {
    isLong(text) {
      return text.length > 500;
    },
    showMore(i, j) {
      const [statusContent] = this.$refs[`content-p-${i}-${j}`];
      const [link] = this.$refs[`content-a-${i}-${j}`];
      const [dots] = this.$refs[`content-span-${i}-${j}`];
      if (statusContent.style.maxHeight === "unset") {
        link.innerHTML = this.$t("dsr.details.btnLabels.readMore");
        dots.style.visibility = "visible";
        statusContent.style.overflow = "hidden";
        statusContent.style.maxHeight = "100px";
      } else {
        link.innerHTML = this.$t("dsr.details.btnLabels.hide");
        dots.style.visibility = "hidden";
        statusContent.style.overflow = "visible";
        statusContent.style.maxHeight = "unset";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dsr-details__history {
  overflow: hidden;

  &__title {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  &__item {
    padding-left: 26px;
    position: relative;

    &:not(:first-child) {
      margin-top: 20px;
    }

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      left: 0px;
      width: 8px;
      height: 8px;
      background-color: #7e93a7;
      border-radius: 9999px;
      z-index: 1;
    }

    &.first:before {
      width: 12px;
      height: 12px;
      left: -2px;
      background-color: #254a5d;
    }

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 3px;
      width: 2px;
      height: calc(100% + 20px);
      background-color: #e6ebed;
    }

    &.last:after {
      height: calc(100% - 10px);
    }
  }

  &__content {
    display: flex;
  }

  &__read-toggle {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__read-more {
    color: #cb333b !important;
    cursor: pointer;
    background: #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }

  &__dots {
    background: #fff;
    padding: 0 4px;
  }

  &__text {
    width: 65%;
    position: relative;
    overflow: hidden;
  }

  &__attachment {
    color: #254a5d;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 8px;
    background: #e9edef;

    &:active,
    &:hover {
      text-decoration: none;
      opacity: 0.8;
    }
  }

  &__file {
    flex: 1;
    margin-left: 12px;
    display: grid;
    grid-gap: 5px 5px;
    grid-template-columns: repeat(auto-fill, minmax(128px, 1fr));
    grid-auto-rows: minmax(min-content, max-content);
  }

  &__file-name {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
</style>
