<template>
  <div>
    <DgModalCustom
      :header="$t('dsr.details.edit.title')"
      variant="large-right"
      :showModal="true"
      @closeModal="closeModal"
    >
      <div class="dsr-edit__form-container">
        <form class="dsr-edit__form" @submit.prevent="showConfirmationModal">
          <div class="dsr-edit__form__field-box">
            <DgInput
              :label="$t('dsr.details.info.labels.email')"
              :attrs="{ value: form.email }"
              @input="onChange('email', $event)"
            />
          </div>
          <div class="dsr-edit__form__field-box">
            <DgInput
              :label="$t('dsr.details.info.labels.firstName')"
              :attrs="{ value: form.first_name }"
              @input="onChange('first_name', $event)"
            />
          </div>
          <div class="dsr-edit__form__field-box">
            <DgInput
              :label="$t('dsr.details.info.labels.lastName')"
              :attrs="{ value: form.last_name }"
              @input="onChange('last_name', $event)"
            />
          </div>
          <div class="dsr-edit__form__field-box">
            <DgInput
              :label="$t('dsr.details.info.labels.subjectRelation')"
              inputType="select"
              :attrs="{ value: form.relation_id }"
              :selectOptions="dataSubjectTypes"
              @change="onChange('relation_id', $event)"
            />
          </div>
          <div class="dsr-edit__form__field-box">
            <DgInput
              :label="$t('dsr.details.info.labels.dsrType')"
              inputType="select"
              :attrs="{ value: form.data_subject_request_type_id }"
              :selectOptions="dataSubjectRequestTypes"
              @change="onChange('data_subject_request_type_id', $event)"
              :help="$t('dsr.details.edit.changeTypeAlert')"
            />
          </div>
          <div class="dsr-edit__form__field-box" v-if="isDsrManuallyCreated">
            <DgCalendar
              v-model="setFormattedInputDate"
              :label="$t('dsr.details.info.labels.createdAt')"
              :date="date"
              :maxDate="new Date()"
            />
          </div>
          <div class="dsr-edit__modal-btns">
            <DgButton class="dsr-edit__modal-btn" variant-style="outline" @click="closeModal">
              {{ $t("dsr.details.edit.cancelBtn") }}
            </DgButton>
            <DgButton class="dsr-edit__modal-btn" nativeType="submit">
              {{ $t("dsr.details.edit.updateBtn") }}
            </DgButton>
          </div>
        </form>
      </div>
    </DgModalCustom>
    <DgModalCustom
      :showModal="showChildModal"
      :confirmation="confirmOptions"
      @closeModal="closeChildModal"
      @cancelClick="closeChildModal"
      @confirmClick="updateDsrDetails"
      class="sm-modal"
    />
  </div>
</template>
<script>
import { Structural } from "@/dgui-customer-components/";

export default {
  name: "DSRDetailsEditModal",
  components: {
    DgModalCustom: Structural.DgModalCustom,
  },
  props: {
    dsrDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      date: this.dsrDetails.created_at,
      showChildModal: false,
      form: {
        email: this.dsrDetails.email,
        first_name: this.dsrDetails.first_name,
        last_name: this.dsrDetails.last_name,
        relation_id: this.dsrDetails.relation_id,
        data_subject_request_type_id: this.dsrDetails.data_subject_request_type_id,
        created_at: this.dsrDetails.created_at,
      },
    };
  },
  computed: {
    dataSubjectRequestTypes() {
      return [
        { value: 1, name: this.$t("dsr.create.dsrTypes.newsletter") },
        { value: 2, name: this.$t("dsr.create.dsrTypes.generalInfo") },
        { value: 3, name: this.$t("dsr.create.dsrTypes.updateData") },
        { value: 4, name: this.$t("dsr.create.dsrTypes.eraseData") },
        { value: 5, name: this.$t("dsr.create.dsrTypes.restrictProcessing") },
        { value: 6, name: this.$t("dsr.create.dsrTypes.transferData") },
        { value: 7, name: this.$t("dsr.create.dsrTypes.objectProcessing") },
        { value: 8, name: this.$t("dsr.create.dsrTypes.enquiry") },
      ];
    },
    dataSubjectTypes() {
      return [
        { value: 11000, name: this.$t("dsr.create.dataSubjectTypes.employee") },
        { value: 11001, name: this.$t("dsr.create.dataSubjectTypes.formerEmployee") },
        { value: 11002, name: this.$t("dsr.create.dataSubjectTypes.customer") },
        { value: 11003, name: this.$t("dsr.create.dataSubjectTypes.interestedPerson") },
        { value: 11005, name: this.$t("dsr.create.dataSubjectTypes.contractor") },
        { value: 11006, name: this.$t("dsr.create.dataSubjectTypes.newsletter") },
      ];
    },
    confirmOptions() {
      return {
        title: this.$t("dsr.details.edit.confirmation.title"),
        description: this.isDsrTypeUnchanged
          ? this.$t("dsr.details.edit.confirmation.description")
          : this.$t("dsr.details.edit.confirmation.alertDescription"),
        cancel: this.$t("dsr.details.edit.cancelBtn"),
        confirm: this.$t("dsr.details.edit.updateBtn"),
      };
    },
    isDsrManuallyCreated() {
      return this.dsrDetails?.created_manually;
    },
    isDsrTypeUnchanged() {
      return this.form.data_subject_request_type_id === this.dsrDetails.data_subject_request_type_id;
    },
    setFormattedInputDate: {
      get() {
        return formatDate(this.form.created_at, "datetime");
      },
      set(value) {
        this.form.created_at = value;
      },
    },
  },
  methods: {
    onChange(attribute, event) {
      this.form[attribute] = event.target.value;
    },
    showConfirmationModal() {
      this.showChildModal = true;
    },
    closeChildModal() {
      this.showChildModal = false;
    },
    updateDsrDetails() {
      this.handleRequest();
    },
    buildUpdatedFormData() {
      let { created_at, ...formData } = this.form;
      if (this.isDsrManuallyCreated) {
        formData = { ...formData, created_at };
      }
      return formData;
    },
    async handleRequest() {
      const { id, dsrid } = this.$route.params;
      try {
        const updatedFormBody = this.buildUpdatedFormData();
        const dsrEditEndpoint = `/api1/v2/customers/${id}/data_subject_requests/${dsrid}`;
        const response = await this.$http({
          url: dsrEditEndpoint,
          method: "PUT",
          data: { data_subject_request: updatedFormBody },
        });
        this.$store.commit("UPDATE_DSR_LIST", response.data.data);

        this.$store.dispatch("fetchDetailsAndHistories", {
          id,
          dsrid,
        });
        this.$store.dispatch("fetchDsrExemptions", {
          id,
          dsrid,
        });
        this.$dgNotification.show({
          variant: "bold",
          type: "success",
          title: this.$t("dsr.details.edit.successMsg"),
        });
        this.closeChildModal();
        this.closeModal();
      } catch (error) {
        const { data = [] } = error;
        if (Array.isArray(data) && data.length) {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: data[0].detail,
          });
        } else {
          this.$dgNotification.show({
            variant: "bold",
            type: "error",
            title: this.$t("dsr.details.edit.errMsg"),
          });
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.dsr-edit {
  &__form-container {
    border-bottom: 1px solid #dcdcdc;
  }
  &__form {
    padding-bottom: 100px;

    &__field-box {
      margin: 18px;

      .dg-ds-input__help-text {
        color: #e03c31;
      }
    }
  }

  &__modal-btns {
    display: flex;
    justify-content: flex-end;
    margin-right: 18px;
    position: absolute;
    right: 30px;
    bottom: 30px;
  }

  &__modal-btn:first-child {
    margin-right: 24px;
  }
}

.bold {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.sm-modal > .dg-modal.dg-modal--confirm {
  padding-top: 6px;
}
</style>
